import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import { dynamiTableStyles } from "../components/UI/DynamicTable/dynamiTable-tw-styles"
import DynamicTable from "../components/UI/DynamicTable/DynamicTable"
import Typography from "../components/UI/Typography/Typography"
import ImageHeroBanner from "../components/ImageHeroBanner/ImageHeroBanner"
import { TEXT_BANNER } from "../components/ImageHeroBanner/ImageHeroBanner-tw-styles"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import { labels, PRODUCTIDs } from "../constants/label.constants"
import { formatPrdName } from "../helpers/BuildImageCardObject"
import { deslashify } from "../helpers/utils"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const ProductDetails = props => {
  const { pageContext } = props
  const { apiData, componentProps, globalComponents, seoData, dataLayer } = pageContext
  const { productData } = apiData || {}
  const { NutritionalTable } = productData || {}

  const [activeTab, setActiveTabe] = useState(0)

  const onChangeTabIndex = index => {
    setActiveTabe(index)
  }

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: "Product Detail Page",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const heroBannerData =
    componentProps?.length &&
    buildHeroBannerData(componentProps[0]?.ImageHeroBanner)
  const imgCardsData =
    componentProps?.length && componentProps[1]?.ImageCardsLayout
  // Building the hero banner component data
  function buildHeroBannerData(data) {
    if (data) {
      data.heading = formatPrdName(productData?.ProductName) || ""
      data.description = productData?.ProductDescription
      data.foregroundImage = {
        url: productData?.ProductImage[0]?.url,
      }
    }
    return data
  }
  return (
    <div>
      <Layout seoData={seoData} globalComponents={globalComponents}>
        <ImageHeroBanner
          {...heroBannerData}
          style={TEXT_BANNER}
          eventClass="event_button_click"
        />
        <ImageCardsLayout {...imgCardsData} />

        {NutritionalTable?.length > 1 && (
          <div className="">
            <Typography
              className={dynamiTableStyles?.tableTitle}
              content={labels.tabletitle}
            />
          </div>
        )}

        {NutritionalTable?.length > 1 && (
          <div className="nutritionFilter text-center">
            <span
              onClick={() => {
                onChangeTabIndex(0)
              }}
              className={activeTab == 0 ? "active" : ""}
            >
              {labels.servings100}
            </span>
            <span
              onClick={() => {
                onChangeTabIndex(1)
              }}
              className={activeTab == 1 ? "active" : ""}
            >
              {labels.servings30}
            </span>
          </div>
        )}

        {NutritionalTable && (
          <DynamicTable
            {...NutritionalTable[activeTab]}
            style={dynamiTableStyles}
            showHeading={NutritionalTable?.length > 1 ? false : true}
          />
        )}
        <div className="rangeINgredTxt sm:px-8 py-[50px] lg:w-[1170px] max-w-full text-[15px] my-[45px] text-center mx-auto">
          <h2>{labels.ingredientsText}</h2>
          <Typography
            content={productData?.IngredientsText}
            br_allowed={false}
          />
        </div>
      </Layout>
    </div>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

ProductDetails.propTypes = {
  pageContext: PropTypes.shape({
    seoData: PropTypes.array,
    apiData: PropTypes.array, 
    componentProps: PropTypes.array,
    globalComponents: PropTypes.array,
    dataLayer: PropTypes.object,
  }),
}

export default ProductDetails
