export const defaultStyle = {
  bannerContent: "relative lg:h-[650px] overflow-hidden bannerContent",
  customwidth:"max-w-[90%] mx-auto",
  gradBox: "lg:h-[450px] h-[324px] overflow-hidden absolute w-1/2 z-[1]",
  imageresponsiveAbout: "image w-full h-auto lg:min-h-[650px] max-h-[650px] min-h-[650px] md:min-h-auto relative sm:top-[0px] md:top-[-125px] lg:top-[0] top-[0px] lg:scale-x-[1.2] lg:scale-y-[1] md:scale-x-[1.25] md:scale-y-[1.25] lg:left-auto",
  imageresponsiveAboutMobile:"image w-full h-auto lg:min-h-[650px] max-h-[650px] min-h-[650px] md:min-h-auto relative sm:top-[0px] md:top-[-125px] lg:top-[0] top-[0px] lg:scale-x-[1.2] lg:scale-y-[1] md:scale-x-[1.45] md:scale-y-[2.5] scale-x-[1.25] scale-y-[1] lg:left-auto md:left-[3%] left-[0px]",
  imageResponsive: "image w-full h-auto lg:min-h-[650px] max-h-[650px] min-h-[650px] md:min-h-auto relative sm:top-[-5px] md:top-[80px] lg:top-[0] top-[0px] lg:scale-x-[1.2] lg:scale-y-[1] md:scale-x-[120%] md:scale-y-[1.5] left-[0px] md:left-[-70px] scale-x-[1] scale-y-[1] lg:left-auto",
  txtcontent: "philyDiffTxt bg-[#4f2170e3] sm:right-[10vw] sm:absolute w-full sm:w-[450px] relative sm:top-0 mb-0 sm:mt-0 mt-[-68%] mx-auto p-[20px] sm:pl-[40px] sm:pt-[40px] text-left z-[2] font-Bwsurco",
  txtcontentAbout : "philyDiffTxt bg-[#4f2170e3] sm:right-[10vw] sm:absolute w-full sm:w-[450px] relative sm:top-0 mb-0 sm:mt-0 mt-[-48%] mx-auto p-[20px] sm:pl-[40px] sm:pt-[40px] text-left z-[2] font-Bwsurco",
  txtHead: "mb-[15px] leading-[1.2] text-white font-Bwsurco sm:text-[3.5rem] text-[2.25rem] sm:max-w-full",
  txtHeads : "mb-[15px] leading-[1.2] text-white font-Bwsurco sm:text-[3.5rem] text-[2.25rem] sm:max-w-full max-w-[152px] pl-[10px] pb-[10px] xl:pr-[9rem]",
  txtDesc: "text-[1.35rem] text-white font-Bwsurco lg:px-[0] lg:mt-auto leading-[150%] lg:max-w-full mx-auto mb-[1rem]",
  textLink: " ",
  heroLink: "px-10 text-sm tracking-wide text-darkBlue font-medium before:content-['/'] before:pr-5",
}



export const contentInRight = {
  bannerContent: "relative",
  image: "image w-full",
  txtcontent: "lg:absolute lg:text-white lg:right-[15%] lg:w-[28%] lg:bg-transparent lg:top-[23%] clear-both my-0 mx-auto px-0 py-8 relative lg:text-left text-white w-full text-center",
  txtHead: "text-4xl mb-1",
  txtDesc: "text-xl",
  textLink: " ",
  imageResponsive: "image",
}

export const RECIPE_DETAIL_HERO = {
  bannerContent: "relative lg:h-[530px] md:h-[350px] h-[425px] top-0 overflow-hidden ",
  imageResponsive: "image w-full  relative lg::top-[78px] lg:top-[-17%] md:top-[-52px] md:scale-[1.2] lg:scale-[1] lg:left-auto",
  txtcontent: "w-full lg:bg-transparent absolute lg:text-white lg:max-w-full top-[40%] lg:top-[36.5%] clear-both my-0 mx-auto px-0 lg:py-8 pt-30  lg:text-left text-white w-full text-center z-[2]",
  txtHead: "lg:w-[900px] lg:text-[50px] text-[35px] lg:leading-[55px] leading-[38px] mb-1 font-ProxExBold text-center heroShadow tracking-[1px] mx-auto",
  txtDesc: "text-xl text-white lg:pt-15 px-15 lg:px-[0] mt-20 lg:mt-auto",
  textLink: " ",
  heroLink: "buttonEffect2 watchVideo",
  backBtn: "w-full lg:bg-transparent absolute text-[15px] lg:text-white lg:max-w-full lg:top-[4%] top-0 clear-both my-0 mx-auto px-0 lg:py-8 py-30 lg:pt-0 text-white w-full text-center z-[2] heroShadow tracking-[0] backButton",
}

export const TEXT_BANNER = {
  heading: "text-3xl text-white text-center heroText mt-16 font-ProxNarrow tracking-wide",
  bannerContent: "relative lg:h-[650px] overflow-hidden bannerContent",
  image: "image w-full",
  txtcontent: "philyDiffTxt bg-[#4f2170e3] sm:right-[10vw] sm:absolute w-full sm:w-[450px] relative sm:top-0 mb-0 sm:mt-0 mt-[-10%] mx-auto p-[20px] sm:pl-[40px] sm:pt-[40px] text-left z-[2] font-Bwsurco text-[2.5rem] sm:text-[3.5rem] text-white leading-tight",
  txtHead: "mb-[15px] leading-[1.2] text-white font-Bwsurco sm:text-[3.5rem] text-[2.25rem] sm:max-w-full",
  txtDesc: "txtDesc text-[15px] max-w-full w-[750px] leading-[30px] my-0 mx-auto lg:mt-0 mt-30 text-center md:px-0 px-8 absolute lg:bottom-[-75%] md:bottom-[-50%] bottom-[-113%] text-darkBlue text-center left-[50%] translate-x-[-50%] translate-y-0 tracking-normal font-normal textBanner",
  textLink: "inline-block leading-7 relative text-white align-middle ",
  imgWrapper: "w-full flex justify-center",
  imgParent: "",
  imgBg: "",
  imageResponsive: "image w-full h-auto min-h-[650px] relative lg:s top-[162px] md:top-[70px] lg:top-[0] scale-x-[2.5] scale-y-[1.5] md:scale-[1.45] lg:scale-x-[1.2] lg:scale-y-[1] lg:left-auto md:left-[-10%] left-[0%]",
  linkWrpr: "",
  backBtn: "lg:bg-transparent absolute text-[15px] lg:text-white left-[50%] translate-x-[-50%] translate-y-0 lg:top-[8%] md:top-[3%] top-0 my-0 mx-auto px-0 lg:py-8 py-30  text-white w-full text-center backButton"
}