import React from "react"
import PropTypes from "prop-types"
import Typography from "../UI/Typography/Typography"
import Link from "../UI/Link/Link"
import Image from "../UI/Image/Image"
import useImage from "../../hooks/useImage"
useImage

const ImageHeroBanner = props => {
  const {
    description,
    heading,
    subHeading,
    link,
    label,
    style,
    backLink,
    underlineText,
    images,
    pageType,
  } = props

  const { altText, foregroundImg, backgroundImg } = useImage(images)

  const {
    bannerContent,
    imageResponsive,
    txtcontent,
    textSubHead,
    gradBox,
    txtHead,
    txtDesc,
    subTitle,
    heroLink,
    backBtn,
    imgParent,
    imgBg,
    imgWrapper,
    eventMenu,
    topNav,
    subTitletext,
    imageresponsiveAbout,
    imageresponsiveAboutMobile
  } = style
  return (
    <div className={bannerContent}>
      <div className={gradBox}></div>
      {foregroundImg && (
        <Image contentfulImage={foregroundImg} className={pageType ==="About Us Page" ? imageresponsiveAboutMobile: imageResponsive} />
      )}

      <div className={imgParent}>
        <div className={imgBg}></div>
        <div className={imgWrapper}>
          {backgroundImg && (
            <Image
              contentfulImage={backgroundImg}
              className={pageType ==="About Us Page" ? imageresponsiveAbout: imageResponsive}
            />
          )}
        </div>
      </div>

      {backLink && (
        <div className={backBtn}>
          <Link {...backLink} />
        </div>
      )}
      <div className={pageType==="About Us Page" ? style?.txtcontentAbout : style?.txtcontent }>
        {underlineText && <div className={subTitletext}>{underlineText}</div>}
        {heading && <Typography className={pageType==="Home Page" ? style?.txtHead : style?.txtHeads} content={heading} />}
        {subHeading && <div className={textSubHead}>{heading}</div>}
        <div className={txtDesc}>
          {description && (
            <Typography className={subTitle} content={description} />
          )}
        </div>
        {link && (
          <Link
            {...link}
            gaEventClass={eventMenu}
            dataActionDetailPrefix={topNav}
            className={heroLink}
          >
            {label}
          </Link>
        )}
      </div>
    </div>
  )
}

ImageHeroBanner.propTypes = {
  description: PropTypes.string,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  images: PropTypes.object,
  underlineText: PropTypes.string,
  buttonText: PropTypes.string,
  link: PropTypes.object,
  label: PropTypes.string,
  variant: PropTypes.string,
  backBtn: PropTypes.object,
  backLink: PropTypes.object,
  style: PropTypes.object,
  pageType: PropTypes.string,
}

export default ImageHeroBanner